<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="container">
          <div class="welcome-text px-4">
            <h1>{{ $t('additional-infoSignup-by-iin.teachers') }}</h1>
          </div>
          <div class="px-4">
            <div class="steps" v-if="this.$i18n.locale === 'ru'">
              <span class="current">{{ $t('signup.step') }} 3</span><span class="total">{{
                $t('signup.out-of')
              }} 4</span>
            </div>
            <div class="steps" v-else-if="this.$i18n.locale === 'kz'">
              <span class="current">4</span><span class="total">{{ $t('signup.out-of') }} 3{{
                $t('signup.ending')
              }}</span>
            </div>
            <form action="#" class="signin-form row">
              <div class="col-md-12">
                <div class="row">

                  <div class="col-lg-4 col-md-12">

                    <form-group
                        :label="  $t('your_math_teacher') ">

                      <el-select v-model="teachersId.maths_id" :placeholder="$t('your_math_teacher')">
                        <el-option
                            v-for="item in teachers_maths"
                            :key="item.id"
                            :label="item.last_name + ' ' + item.first_name"
                            :value="item.id">
                        </el-option>
                      </el-select>

                    </form-group>
                    <form-group
                        :label="  $t('your_English_teacher') ">

                      <el-select v-model="teachersId.english_lang_id" :placeholder="$t('your_English_teacher')">
                        <el-option
                            v-for="item in teachers_en"
                            :key="item.id"
                            :label="item.last_name + ' ' + item.first_name"
                            :value="item.id">
                        </el-option>
                      </el-select>

                    </form-group>


                  </div>
                  <div class="col-lg-4 col-md-12">
                    <form-group
                        :label="  $t('your_science_teacher') ">

                      <el-select v-model="teachersId.natural_science_id" :placeholder="$t('your_science_teacher')">
                        <el-option
                            v-for="item in teachers_nature"
                            :key="item.id"
                            :label="item.last_name + ' ' + item.first_name"
                            :value="item.id">
                        </el-option>
                      </el-select>

                    </form-group>
                    <form-group
                        :label="  $t('your_Kazakh_teacher') ">

                      <el-select v-model="teachersId.kazakh_lang_id" :placeholder="$t('your_Kazakh_teacher')">
                        <el-option
                            v-for="item in teachers_kk"
                            :key="item.id"
                            :label="item.last_name + ' ' + item.first_name"
                            :value="item.id">
                        </el-option>
                      </el-select>

                    </form-group>

                  </div>
                  <div class="col-lg-4 col-md-12">
                    <form-group
                        :label="  $t('your_Russian_teacher') ">

                      <el-select v-model="teachersId.russian_lang_id" :placeholder="$t('your_Russian_teacher')">
                        <el-option
                            v-for="item in teachers_ru"
                            :key="item.id"
                            :label="item.last_name + ' ' + item.first_name"
                            :value="item.id">
                        </el-option>
                      </el-select>

                    </form-group>
                  </div>
                  <div style="margin-left: auto;margin-right: 15px" class="form-group text-center pt-3 btn-controls">
                    <!-- to="/signup-by-IIn/phone-confirm" -->
                    <router-link
                        type="primary"
                        style="margin-left: 0; margin-bottom: 1rem"
                        class="btn-next"
                        :to="{ name: 'signup-second-step' }"
                        tag="el-button"
                    >
                      {{ $t('school.back-btn') }}
                    </router-link>
                    <el-button
                        style="color: white; background-color: #409EFF"
                        type="button"
                        class="btn-next"
                        @click="setTeacherId"
                    >
                      {{ $t('signup.next-btn') }}
                    </el-button>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'UserSignupTeachers',
  data() {
    return {
      teachers_maths: [],
      teachers_ru: [],
      teachers_en: [],
      teachers_kk: [],
      teachers_nature: [],
      teachers: [],
      teachersId: {
        maths_id: '',
        russian_lang_id: '',
        english_lang_id: '',
        kazakh_lang_id: '',
        natural_science_id: '',
      },
    }
  },
  mounted() {
    let accountSessionData = JSON.parse(sessionStorage.getItem('account'))
    let schoolId = accountSessionData.school_id

    this.$http.get(window.API_ROOT + `/api/references/teachers/${schoolId}/2`)
        .then((res) => {
          if (res.body) {
            this.teachers_kk = res.body;
          }
        })
        .catch((e) => {
        });

    this.$http.get(window.API_ROOT + `/api/references/teachers/${schoolId}/3`)
        .then((res) => {
          if (res.body) {
            this.teachers_maths = res.body;
          }
        })
        .catch((e) => {
        });

    this.$http.get(window.API_ROOT + `/api/references/teachers/${schoolId}/5`)
        .then((res) => {
          if (res.body) {
            this.teachers_en = res.body;
          }
        })
        .catch((e) => {
        });

    this.$http.get(window.API_ROOT + `/api/references/teachers/${schoolId}/1`)
        .then((res) => {
          if (res.body) {
            this.teachers_ru = res.body;
          }
        })
        .catch((e) => {
        });

    this.$http.get(window.API_ROOT + `/api/references/teachers/${schoolId}/4`)
        .then((res) => {
          if (res.body) {
            this.teachers_nature = res.body;
          }
        })
        .catch((e) => {
        });
  },
  methods: {
    setTeacherId() {
      let accountSessionData = JSON.parse(sessionStorage.getItem('account')) ?? {}

      // this.teachers.push(this.teachers_maths.find(item => item.id === this.teachersId.maths_id))
      // this.teachers.push(this.teachers_en.find(item => item.id === this.teachersId.english_lang_id))
      // this.teachers.push(this.teachers_kk.find(item => item.id === this.teachersId.kazakh_lang_id))
      // this.teachers.push(this.teachers_ru.find(item => item.id === this.teachersId.russian_lang_id))
      // this.teachers.push(this.teachers_nature.find(item => item.id === this.teachersId.natural_science_id))

      this.teachers.push(this.teachersId.maths_id)
      this.teachers.push(this.teachersId.english_lang_id)
      this.teachers.push(this.teachersId.kazakh_lang_id)
      this.teachers.push(this.teachersId.russian_lang_id)
      this.teachers.push(this.teachersId.natural_science_id)

      this.teachers = this.teachers.filter( item => item !== '')

      accountSessionData.teachers = this.teachers

      sessionStorage.setItem('account', JSON.stringify(accountSessionData))

      this.teachers = []
      this.$router.push({ path: '/signup-by-IIn/additional-info' });
    },
    async routeSignupHandler() {
      try {
        const res = await this.$http.get(`${window.API_ROOT}/api/check-time`)
        if (res.body.code === 403) {
          this.$router.push('/')
          Vue.toastr({
            message: '',
            description: this.$t('navbar.registration-alert').replace('year', new Date().getFullYear()),
            type: 'info'
          });
        }
      } catch (error) {
        this.$router.push('/')
        Vue.toastr({
          message: this.$t('error'),
          description: error,
          type: 'error'
        });
      }
    },
  }
}
</script>

<style lang="css" scoped>
.el-select {
  margin-bottom: 0px;
}


.input_text {
  -webkit-appearance: none;
  background-color: #FFF;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: 16px;
  height: 46px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  width: 100%;
}
</style>
